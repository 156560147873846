@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,800');

$navbar-height: 6.25rem;
$turquoise: #00d3ca;
$purple: #2b046f;

$primary-font: 'Montserrat';

$family-primary: $primary-font;

.has-text-purple {
  color: $purple;
}

.has-text-turquoise {
  color: $turquoise;
}

* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}

a,
button {
  transition: 0.3s all linear;
}

a:hover,
a:focus,
a:active,
button:hover,
button:focus,
button:active {
  text-decoration: none;
  opacity: 0.7;
  outline: none;
}

.list-style-default {
  ul {
    list-style: initial;
    margin: initial;
    padding: 0 0 0 40px;
  }
  li {
    display: list-item;
  }
}
