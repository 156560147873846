.main-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

}

.container {
    overflow-x: scroll;
}

.col {
    width: 100%;

    @media (min-width: 992px) {
        width: 50%;
    }
}

.title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
}

.buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .button {
        margin-top: 10px;
        margin-bottom: 10px;

        @media (min-width: 540px) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.button {
    min-width: 140px;
}

.table tr.is-collapsed,
.table tr.not-collapsed {
    cursor: pointer;

    td:first-of-type {
        position: relative;
        padding-left: 40px;

        &::before {
            position: absolute;
            left: 10px;
            content: '>';
            color: #3273dc;
        }
    }
}

.table tr.not-collapsed {
    td:first-of-type {
        &::before {
            transform: rotate(270deg) scaleX(.9) scaleY(1.5);
        }
    }
}

.table tr.is-collapsed {
    td:first-of-type {

        &::before {
            transform: rotate(90deg) scaleX(.9) scaleY(1.5);
        }
    }
}

th.is-centered {
    border-bottom: 1px solid #dbdbdb !important;
    text-align: center !important;
}

.table td.is-collapse {
    padding: 0 !important;
    border-bottom: 1px solid #dbdbdb !important;
}

.table th,
.table td {
    text-align: center !important;
    vertical-align: top !important;

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5) {
        width: 20%;
    }
}

.table td {
    white-space: nowrap;
}

.table-title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
}
