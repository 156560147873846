@import 'react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
  border: 1px solid #00d3ca;
  border-radius: 4px;
  font-size: 0.8rem;
  &__input-container input {
    width: 100%;
    padding: 2px 35px 2px 8px;
    min-height: 38px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    outline-color: #00d3ca;
    color: #4c4c4c;
    &:hover:enabled {
      border: 1px solid #b3b3b3;
    }
  }
  &__navigation:hover *::before {
    border-color: #fff;
  }
  &__navigation-icon--previous::before,
  &__navigation-icon--next::before {
    border-color: #fff;
    top: 12px;
  }
  &__header {
    background-color: #00d3ca;
    border-bottom: 1px solid #00d3ca;
    border-radius: 0px !important;
    color: #fff;
    .react-datepicker-time__header {
      color: #fff;
    }
  }
  &__current-month,
  &__day-name {
    color: #fff;
  }
  &__time-list-item {
    line-height: 30px;
  }
  &__day,
  &__time-list-item {
    &:hover {
      background-color: #94e9e6 !important;
    }
    &--selected {
      background-color: #00d3ca !important;
    }
  }
}
