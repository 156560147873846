.topbar-container {
  padding-top: 75px;
  padding-bottom: 30px;
  text-align: center;

  @media (min-width: 768px) {
      padding: 40px 15px;
  }
}

.topbar-title {
  font-size: 44px;
  font-weight: 700;
}

.topbar-logo {
  top: 15px;
  left: 15px;
  font-size: 1.5rem;
  font-weight: 700;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  @media (min-width: 768px) {
    top: 22px;
    font-size: 2.5rem;
  }
}

.topbar-logout {
  top: 20px;
  right: 15px;
  background-color: transparent;
  border-width: 0;
  outline: none;
  font-size: 16px;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 40px;
  }
}

.topbar-link {
  top: 50px;
  left: 15px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;

  @media (min-width: 768px) {
    top: 80px;
  }

  &-2 {
    top: 70px;
    
    @media (min-width: 768px) {
      top: 100px;
    }
  }

}

.topbar-lang {
  color: #4a4a4a;
  font-size: 16px;
  cursor: pointer;
  text-transform: capitalize;

  &-wrapper {
    position: absolute;
    top: 20px;
    right: 90px;

    @media (min-width: 768px) {
      top: 40px;
    }
  }

  &-flag {
    margin-top: -3px;
  }

  &:hover,
  &:active,
  &:focus {
    color: #4a4a4a;
  }
}

.topbar-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(10, 22, 50, 0.16);
  margin-top: 10px;
  border-radius: 8px;
  width: 140px;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 9999;
  p {
    transition: all 0.2s linear;
    font-size: 16px;
    line-height: 19px;
    color: #4a4a4a;
    cursor: pointer;
    padding: 14px 16px;
    margin-bottom: 0;
    &:hover {
      background-color: #fff;
    }
  }
}


.topbar-logo,
.topbar-logout,
.topbar-link {
  position: absolute;
  color: #4a4a4a;

  &:hover,
  &:active,
  &:focus {
    color: #4a4a4a;
  }
}
