@keyframes animation-loading {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.location-search-input {
  width: 100%;
  padding: 2px 35px 2px 8px;
  min-height: 38px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline-color: #00d3ca;
  color: #4c4c4c;
  &:hover:enabled {
    border: 1px solid #b3b3b3;
  }
  &::placeholder {
    color: hsl(0, 0%, 50%);
  }
}

.autocomplete-dropdown-container {
  background-color: #ffffff;
  position: absolute;
  z-index: 1000;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #0000001a, 0 4px 11px #0000001a;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
}

.suggestion-item,
.suggestion-item-active {
  background-color: transparent;
  cursor: default;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  &:hover {
    background-color: #94e9e6;
  }
}

.loading-indicator {
  color: hsl(0, 0%, 80%);
  display: flex;
  padding: 8px;
  transition: color 150ms;
  align-self: center;
  font-size: 4px;
  line-height: 1;
  margin-right: 4px;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.loading-dot-1,
.loading-dot-2,
.loading-dot-3 {
  background-color: currentColor;
  border-radius: 1em;
  display: inline-block;
  height: 1em;
  vertical-align: top;
  width: 1em;
}

.loading-dot-1 {
  animation: animation-loading 1s ease-in-out 0ms infinite;
}

.loading-dot-2 {
  animation: animation-loading 1s ease-in-out 160ms infinite;
  margin-left: 1em;
}

.loading-dot-3 {
  animation: animation-loading 1s ease-in-out 320ms infinite;
  margin-left: 1em;
}
