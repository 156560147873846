@import 'styles/custom.scss';

.day-control {
  margin-right: 10px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.graph-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 960px), print {
    flex-direction: row;
  }
  @media screen and (min-width: 1150px), print {
    width: 90%;
  }
}

.graph {
  // margin: 20px auto 0;
  // max-width: 800px !important;
  flex: 0 100%;
  margin-bottom: 20px;
  @media screen and (min-width: 960px), print {
    flex: 0 49%;
  }
}

.graph-content {
  height: 400px;
  max-width: 100%;
}

.small-graph-content {
  height: 400px;
  width: 700;
}

.card,
.card-wide {
  max-width: 420px !important;
  margin: 0 auto;

  @media (min-width: 540px) {
    width: 420px;
  }
}

.card-wide {
  max-width: 550px !important;

  @media (min-width: 540px) {
    width: 550px;
  }
}

.container {
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
}

.row {
  display: flex;
  width: 100%;
  color: black;
}

.row div:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row div:last-child {
  display: flex;
  align-items: center;
}

.row div:first-child p:first-child {
  color: gray;
  font-weight: bold;
}

.row div:first-child p:last-child {
  font-size: 14px;
  color: gray;
}

.error-container,
.loading-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.toplist-header {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.icon-activity {
  fill: $turquoise;
  width: calc(32px + 0.8vw);
  height: calc(32px + 0.8vw);
}

.icon-screenTime {
  width: calc(26px + 0.8vw);
  height: calc(26px + 0.8vw);
  fill: $purple;
  margin-bottom: 5px;
}

.time-field {
  flex: 0 50%;
  margin-left: 15px;
}
